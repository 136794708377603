import { MatchLocalization } from "./tree";
import { googleTranslateReq } from "api/google";

export const languages = ["uk", "en", "de", "fr", "es", "kk", "pl", "ru"] as const;

export type Language = (typeof languages)[number];

const getTranslation = async (valueToTranslate: string, targetLanguage: Language) =>
  (await googleTranslateReq(valueToTranslate, targetLanguage)).json();

export const getTranslations = async (valueToTranslate: string) => {
  const translations = await Promise.all(languages.map((language) => getTranslation(valueToTranslate, language)));
  return languages.map((language, index) => ({ language, name: translations[index].data.translations[0].translatedText }));
};

export const createEmptyMatchLocalizations = (): MatchLocalization[] => {
  return languages.map((language) => ({
    language,
    team1: "",
    team2: "",
    time: "",
  }));
};

export const getMatchTranslations = async (team1: string, team2: string, time: string): Promise<MatchLocalization[]> => {
  const translationsTeam1 = await Promise.all(languages.map((language) => getTranslation(team1, language)));
  const translationsTeam2 = await Promise.all(languages.map((language) => getTranslation(team2, language)));

  return languages.map((language, index) => ({
    language,
    team1: translationsTeam1[index].data.translations[0].translatedText,
    team2: translationsTeam2[index].data.translations[0].translatedText,
    time,
  }));
};
